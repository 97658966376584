import React, { useState, useEffect } from 'react'
import { chakra, Button, Text, Box, Image, Heading, HStack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { history } from '../../../../../../../history'
import { useEventHandlersV2 } from '../../../../../../hooks/useEventHandlers'
import { useEventContext } from '../../../../../../contexts/EventProvider'
import { useEventCashCollection } from '../../../../Event'
import { CheckCircleRounded, ArrowForwardRounded } from '../../../../../atoms/Icon'

const cardStyle = {
	backgroundColor: 'transparent',
	border: '1px solid',
	borderColor: 'whiteAlpha.300',
	borderTop: 'none',
	marginTop: '-1rem', // remove margin-bottom from above element
	mx: 'auto',
	maxW: ['calc(100% - 2rem)', 'calc(100% - 2rem)', 'calc(100% - 2rem)', '45%'],
	width: 'calc(100% - 2rem)',
	p: '1rem',
	borderRadius: '0 0 1rem 1rem',
	backgroundClip: 'padding-box',
	position: 'relative',
}

const GiftCardSelector = () => {
	const { event } = useEventContext()
	const { uuid } = useParams()
	const handlers = useEventHandlersV2(uuid)
	const { funders, amount } = useEventCashCollection(event)

	/**
	 * Total Amount $
	 * */
	const [total, setTotal] = useState(amount)
	useEffect(() => {
		setTotal(amount)
	}, [amount])

	const [isRedeemable, setIsRedeemable] = useState(funders.filter((f) => f.paid).filter((f) => !f.claimed))
	useEffect(() => {
		const claimable = funders.filter((f) => f.paid).filter((f) => !f.claimed)
		setIsRedeemable(claimable.length > 0)
	}, [event])

	const handleClick = () => {
		history.push(`${handlers.public_redeem}?v=${event.privateKey}`)
	}

	if (!isRedeemable) {
		return (
			<HStack __css={cardStyle} justifyContent="space-between" height="3.5rem">
				<Text fontSize=".875rem" textAlign="left">
					Gift Card Claimed
					<CheckCircleRounded color="green.400" transform="scale(.875)" ml="0.5rem" />
				</Text>
				<Button fontSize=".875rem" color="white" variant="link" onClick={handleClick}>
					See Details
				</Button>
			</HStack>
		)
	} else {
		return (
			<Box __css={cardStyle} height="9rem" marginBottom={['6rem', '5rem']} position="relative">
				<Text textAlign="center" fontSize=".875rem" mb="1rem">
					Funds were collected to <chakra.strong color="white">claim as a gift card</chakra.strong>.
				</Text>
				<Box position="relative" onClick={handleClick} cursor="pointer" w="14rem" h="8.25rem" m="auto">
					<Image
						position="absolute"
						__css={{ pointerEvent: 'none' }}
						left="-2.5rem"
						bottom={['5rem', '4rem']}
						src={'/assets/images/gift-card/claim-gift-card-star-left.svg'}
						alt="Claim Gift Card star"
					/>
					<Image
						position="absolute"
						__css={{ pointerEvent: 'none' }}
						right="-2.5rem"
						bottom={['5rem', '4rem']}
						src={'/assets/images/gift-card/claim-gift-card-star-right.svg'}
						alt="Claim Gift Card star"
					/>
					<Image
						position="absolute"
						w="14rem"
						h="8.25rem"
						src={'/assets/images/gift-card/claim-gift-card.svg'}
						alt="Claim Gift Card"
					/>
					<Box position="absolute" alignItems="flex-end" spacing="0" right="1rem" top=".75rem">
						<Heading variant="hero" color="white">
							${total / 100}
						</Heading>
					</Box>
					<Button
						position="absolute"
						size="sm"
						variant="invert"
						height="2.125rem"
						right="1rem"
						bottom=".75rem"
						onClick={handleClick}>
						Claim <ArrowForwardRounded />
					</Button>
				</Box>
			</Box>
		)
	}
}

export default GiftCardSelector
