import React from 'react'
import { useEventContext } from '../../../../contexts/EventProvider'
import { useSearchParams } from 'react-router-dom'
import Private from './Private'
import PublicOrRecipient from './PublicOrRecipient'
import { useRoleContext } from '../../../../contexts/RoleProvider'
import FancyLoader from '../../../atoms/FancyLoader'

const PublishedRoot = () => {
	const { event } = useEventContext()
	const { role } = useRoleContext()
	const [search] = useSearchParams()
	const privateKey = search.get('v')

	if (event) {
		return <PublicOrRecipient />
		// if (role == 'recipient' && privateKey && event.privateKey == privateKey) {
		// 	return <PublicOrRecipient />
		// } else if (event.isPrivate) {
		// 	return <Private />
		// } else {
		// 	return <PublicOrRecipient />
		// }
	}
	return <FancyLoader />
}

export default PublishedRoot
