import React from 'react'
import { Container, Image, Text, VStack, Heading } from '@chakra-ui/react'
import PublicHeader from '../../atoms/PublicHeader'
import PublicFooter from '../../atoms/PublicFooter'
import Card from '../../../../atoms/Card'
import useImageGenerator from '../../../../../hooks/useImageGenerator'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { useSelector, shallowEqual } from 'react-redux'
import { PresentationNav } from '../PublicOrRecipient'
import { Helmet } from 'react-helmet'
import ThankYouSelector from '../../../../molecules/ThankYouSelector'

const Private = () => {
	const img = useImageGenerator({
		path: '/assets/images/graphics/see-no-evil-monkey',
		ext: 'png',
	})

	const { event, isOwner } = useEventContext()
	const video = useSelector((s) => s.event.thankYouVideo, shallowEqual)

	return (
		<>
			<Helmet>
				<meta httpEquiv="refresh" content={300} />
			</Helmet>

			{isOwner && <PresentationNav event={event} />}

			<PublicHeader />

			<Container maxW="container.lg" pt="2rem" pb="1rem" px="1rem" variant="main">
				<Card py="2rem" px={['1rem', '2rem']}>
					<VStack spacing="1rem" w="full">
						<Image alt="Private Video" src={img.src} srcSet={img.srcset} boxSize="4rem" />

						<Heading as="h2" size="lg" variant="hero" color="dark">
							This video is private.
						</Heading>
						<Text color="dark" textAlign="center">
							<strong>Want to watch the video?</strong> Ask the creator to make it available to
							contributors.
						</Text>
					</VStack>
				</Card>

				<ThankYouSelector video={video} role={'public'} event={event} mt="1rem" />
			</Container>

			<PublicFooter />
		</>
	)
}

export default Private
