import React, { useState } from 'react'
import {
	NavCard,
	NavCardContent,
	NavCardCover,
	NavCardActions,
	NavCardButton,
} from '../../../../../atoms/NavigationCard'
import { useRoleContext } from '../../../../../../contexts/RoleProvider'
import { useDispatch } from 'react-redux'
import { updateEvent } from '../../../../../../../api/app/events'
import Toggle from '../../../../../atoms/Toggle'
import { Image } from '@chakra-ui/react'

export const VisibilitySelector = ({ event, onClick, variant, ...rest }) => {
	const { role } = useRoleContext()
	const dispatch = useDispatch()
	/**
	 * Handler to make the event private/public
	 */
	const [isPrivate, setIsPrivate] = useState(event.isPrivate)
	const handleTogglePrivate = () => {
		let eventData = { id: event.id, uuid: event.uuid, isPrivate: !event.isPrivate, privateKey: event.privateKey }
		dispatch(updateEvent(eventData, true))
		setIsPrivate(!isPrivate)
	}

	const cover = isPrivate
		? '/assets/images/graphics/navigation-cards/vidday-video-hidden'
		: '/assets/images/graphics/navigation-cards/vidday-video-viewable'

	return (
		<NavCard
			onClick={onClick ? onClick : handleTogglePrivate}
			variant={role == 'recipient' || role == 'public' ? 'outline' : null}
			position="relative"
			{...rest}>
			<NavCardCover path={cover} ext="png" />
			<NavCardContent
				title={isPrivate ? 'Private' : 'Shareable'}
				description={isPrivate ? `Video is not shareable` : `Share the video link`}
			/>
			<NavCardActions>
				<Toggle value={!isPrivate} onToggle={isPrivate ? handleTogglePrivate : () => {}} theme="visibility" />
			</NavCardActions>
			{isPrivate && (
				<Image
					sx={{
						display: 'block',
						position: 'absolute',
						top: '92px',
						right: '3rem',
					}}
					src="/assets/images/graphics/navigation-cards/share-with-participants.svg"
				/>
			)}
		</NavCard>
	)
}
