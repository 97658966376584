import React from 'react'
import { VStack, HStack, Link, Spacer } from '@chakra-ui/react'

const socialStyles = {
	cursor: 'pointer',
	content: '""',
	w: '55px',
	h: '55px',
	backgroundColor: 'white',
	borderRadius: 'full',
	textIndent: '-9999px',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
}

const networkStyles = [
	{
		'backgroundSize': '12px 25px',
		'backgroundImage': "url('/assets/images/social/social-facebook.svg')",
		'href': 'https://www.facebook.com/ViddayGift/',
		'target': '_blank',
		'rel': 'nofollow',
		'className': 'facebook',
		'aria-label': 'Facebook Page',
	},
	{
		'backgroundSize': '26px 26px',
		'backgroundImage': "url('/assets/images/social/social-instagram.svg')",
		'href': 'https://www.instagram.com/viddaygift/',
		'target': '_blank',
		'rel': 'nofollow',
		'className': 'instagram',
		'aria-label': 'Instagram Page',
	},
	{
		'backgroundSize': '32px 22px',
		'backgroundImage': "url('/assets/images/social/social-youtube.svg')",
		'href': 'https://www.youtube.com/c/Vidday',
		'target': '_blank',
		'rel': 'nofollow',
		'className': 'youtube',
		'aria-label': 'YouTube Channel',
	},
	{
		'backgroundSize': '22px 27px',
		'backgroundImage': "url('/assets/images/social/social-pinterest.svg')",
		'href': 'https://www.pinterest.ca/VidDay/',
		'target': '_blank',
		'rel': 'nofollow',
		'className': 'pinterest',
		'aria-label': 'Pinterest Page',
	},
	{
		'backgroundSize': '23px 23px',
		'backgroundImage': "url('/assets/images/social/social-X.svg')",
		'href': 'https://twitter.com/viddaygift',
		'target': '_blank',
		'rel': 'nofollow',
		'className': 'X',
		'aria-label': 'X Page',
	},
]

const Socials = () => {
	return (
		<VStack textAlign="center" spacing="1rem" mt="4rem">
			<HStack spacing="1rem">
				{networkStyles.map((el, i) => (
					<Link key={i} sx={socialStyles} {...el} />
				))}
			</HStack>
		</VStack>
	)
}

export default Socials
